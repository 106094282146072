import { doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';

export interface Feature {
  name: string;
  description: string;
  user: string;
  enabled: boolean;
  datetime_modified: string;
}

export type FeaturesResponse = Feature[];

export const getFeatures = () => {
  return doGet<FeaturesResponse>('feature_flags', LOGGER_TYPE.FEATURES);
};

export const createFeature = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  return doPost<FeaturesResponse>('feature_flags', LOGGER_TYPE.FEATURES, [
    {
      name,
      description,
      enabled: true,
    },
  ]);
};

export const updateFeature = ({
  name,
  enabled,
}: {
  name: string;
  enabled: boolean;
}) => {
  return doPut<FeaturesResponse>('feature_flags', LOGGER_TYPE.FEATURES, {
    name,
    enabled: enabled,
  });
};
