import { SUPPORT_EMAIL } from 'constants/EmailUs.constants';

import Callout, { CalloutType } from '@polyai/ui/components/atoms/Callout';

import { ACCOUNT_MODAL } from '../AccountModal.uiCopy';

import * as Styled from './AccountModalContent.styled';

type AccountModalContentProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

const AccountModalContent: React.FC<AccountModalContentProps> = ({
  firstName,
  lastName,
  email,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {[
          {
            title: ACCOUNT_MODAL.details.firstName,
            value: firstName,
          },
          {
            title: ACCOUNT_MODAL.details.lastName,
            value: lastName,
          },
          {
            title: ACCOUNT_MODAL.details.password,
            value: '●●●●●●●●',
          },
          {
            title: ACCOUNT_MODAL.details.email,
            value: email,
          },
        ].map(({ title, value }, index) => (
          <Styled.Item key={index}>
            <Styled.ItemTitle as="dt">{title}</Styled.ItemTitle>
            <Styled.ItemValue
              $letterSpacingExtraLoose={
                title === ACCOUNT_MODAL.details.password
              }
              as="dd"
            >
              {value}
            </Styled.ItemValue>
          </Styled.Item>
        ))}
      </Styled.List>
      <Callout isVisibleBorder={false} type={CalloutType.INFO}>
        {ACCOUNT_MODAL.helpText.updateInfo(
          <Styled.SupportEmail href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Styled.SupportEmail>,
        )}
      </Callout>
    </Styled.Wrapper>
  );
};

export default AccountModalContent;
