import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useMatchPath from '@polyai/common/hooks/useMatchPath';
import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { PATHS } from 'routes/routes.constants';

const useSampleProject = () => {
  const { projectId } = useRequiredParams<{ projectId: string }>();

  const matchPath = useMatchPath();
  const inSampleProject = useMemo(
    () => !!matchPath(PATHS.SAMPLE_PROJECT),
    [matchPath],
  );

  const {
    data: sampleProject,
    isLoading: isLoadingSampleProject,
    isError: didSampleProjectError,
  } = useQuery(
    [projectId, 'sample-project'],
    () => api.getSampleProject(projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: inSampleProject && !!projectId,
    },
  );

  return useMemo(
    () => ({
      sampleProject: sampleProject,
      isLoadingSampleProject,
      didSampleProjectError,
      inSampleProject,
    }),
    [
      sampleProject,
      isLoadingSampleProject,
      didSampleProjectError,
      inSampleProject,
    ],
  );
};

export default useSampleProject;
