import { useAuth0 } from '@auth0/auth0-react';

import {
  ConfirmationModal,
  ConfirmationModalType,
} from '@polyai/ui/components/molecules/ConfirmationModal';

import { ACCOUNT_MODAL } from './AccountModal.uiCopy';
import AccountModalContent from './AccountModalContent';

interface AccountModalProps extends ConfirmationModalType {}

const AccountModal: React.FC<AccountModalProps> = (props) => {
  const { user } = useAuth0();

  return (
    <ConfirmationModal
      buttonText={ACCOUNT_MODAL.button.confirm}
      confirmButtonVariant="PRIMARY"
      content={
        <AccountModalContent
          email={user?.email}
          firstName={user?.given_name}
          lastName={user?.family_name}
        />
      }
      hasCancelButton={false}
      size="md"
      title={ACCOUNT_MODAL.title}
      {...props}
    />
  );
};

export default AccountModal;
