import { CompoundType } from '@polyai/common/types/helpers';

type AssistantVoice = {
  name: string;
  id: string;
  provider: string;
};

export type AssistantVoiceOptionMetadata = {
  description: string;
  accent: string;
  gender: string;
  descriptive: string;
  suggested_use: string;
  sample_voice: string[];
  tags: string[];
};

export type AssistantVoiceOption = CompoundType<
  AssistantVoice & {
    voice_metadata: AssistantVoiceOptionMetadata;
    provider_voice_id: string;
    config: {
      [key: string]: number;
    };
  }
>;

export type TuningSetting = {
  stability: number;
  similarity_boost: number;
  style?: number;
  optimize_streaming_latency?: number;
};

export type AssistantVoiceTuningSetting = {
  [voiceId: string]: TuningSetting;
};

export type VoiceOptionsResponse = {
  voices: AssistantVoiceOption[];
  voice_tuning_settings?: AssistantVoiceTuningSetting;
};

export type ModelOption = {
  id: string;
  name: string;
  provider_model_id: string;
  model_metadata: {
    [key: string]: string;
  };
  config: {
    [key: string]: string;
  };
};

export type ModelOptionsResponse = {
  models: ModelOption[];
};

export enum ContentFilterOption {
  VIOLENCE = 'violence',
  HATE = 'hate',
  SEXUAL = 'sexual',
  SELFHARM = 'self_harm',
  JAILBREAK = 'jailbreak',
}

export enum ContentFilterPrecision {
  LOOSE = 'LOOSE',
  MEDIUM = 'MEDIUM',
  STRICT = 'STRICT',
}

export type ContentFilter = Omit<
  Record<
    ContentFilterOption,
    {
      is_active: boolean;
      precision: ContentFilterPrecision;
    }
  >,
  'jailbreak'
>;

export type AssistantConfig = {
  genai_project_id: string;
  updated_by: string;
  updated_at: string;
  voice_id: string;
  model_id: string;
  default_handoff_id: string;
  config: Record<string, string>;
  content_filter: {
    config: ContentFilter;
    type: 'AZURE';
  } | null;
  barge_in_enabled: boolean;
};

export type UpdateAssistantConfig = {
  voice_id?: string;
  model_id?: string;
  default_handoff_id?: string;
  config?: Record<string, string>;
};
