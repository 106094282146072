import { useLayoutEffect, useMemo, useState } from "react";

import { useTheme } from "styled-components";

import { Breakpoints } from "../styles/config/breakpoints";

export const useBreakpoint = (breakpoint: keyof Breakpoints) => {
  const theme = useTheme();
  const [screenWidth, setScreenWidth] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return useMemo(
    () => !!screenWidth && theme.breakpoints[breakpoint] <= screenWidth,
    [breakpoint, screenWidth, theme.breakpoints]
  );
};
