import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get as getFromLocalStorage } from 'local-storage';

import { closeSidePanel, projectInit } from 'actions';

export type SidePanelType = 'node' | 'audio-testing' | 'flow-function-edit';
type SaveState = 'saving' | 'saved' | 'idle';
export const MIN_SIDE_PANEL_WIDTH = 415;

export interface LayoutState {
  isMainNavOpen: boolean;
  isActionHeaderOpen: boolean;
  sidePanelWidths: Record<SidePanelType, number>;
  sidePanelOpen: SidePanelType | null;
  saveState: SaveState;
}

const initialState: LayoutState = {
  isMainNavOpen: true,
  isActionHeaderOpen: false,
  sidePanelWidths: {
    node: getFromLocalStorage('polyai.sidePanelWidth') || MIN_SIDE_PANEL_WIDTH,
    'audio-testing': 650,
    'flow-function-edit': MIN_SIDE_PANEL_WIDTH,
  },
  sidePanelOpen: null,
  saveState: 'idle',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openMainNav: (state) => {
      state.isMainNavOpen = true;
    },
    closeMainNav: (state) => {
      state.isMainNavOpen = false;
    },
    toggleMainNav: (state) => {
      state.isMainNavOpen = !state.isMainNavOpen;
    },
    openSidePanel: (state, { payload: id }: PayloadAction<SidePanelType>) => {
      state.sidePanelOpen = id;
    },

    setSavedState: (
      state,
      { payload: saveState }: PayloadAction<SaveState>,
    ) => {
      state.saveState = saveState;
    },
    setSidePanelWidth: (
      state,
      {
        payload: { id, width },
      }: PayloadAction<{
        id: SidePanelType;
        width: number;
      }>,
    ) => {
      state.sidePanelWidths[id] = width;
    },
    setIsActionHeaderOpen: (
      state,
      { payload: open }: PayloadAction<boolean>,
    ) => {
      state.isActionHeaderOpen = open;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeSidePanel, (state) => {
        state.sidePanelOpen = null;
      })
      .addCase(projectInit, () => {
        return { ...initialState };
      });
  },
});

export const {
  openMainNav,
  closeMainNav,
  toggleMainNav,
  setSidePanelWidth,
  openSidePanel,
  setSavedState,
  setIsActionHeaderOpen,
} = layoutSlice.actions;

export default layoutSlice.reducer;
