import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import { projectInit } from 'actions';

export interface PreviewState {
  clientEnv: ClientEnvironment;
  deploymentId: string | null;
}
export interface ProjectState {
  id: string;
  activeLanguageCode: string;
  preview: PreviewState;
}

const initialState: ProjectState = {
  id: '',
  activeLanguageCode: '',
  preview: {
    clientEnv: ClientEnvironment.SANDBOX,
    deploymentId: null,
  },
};

const jupiterProjectSlice = createSlice({
  name: 'jupiterProjectInfo',
  initialState,
  reducers: {
    setProjectId: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
    setActiveLanguageCode: (state, { payload }: PayloadAction<string>) => {
      state.activeLanguageCode = payload;
    },
    setPreview: (state, { payload }: PayloadAction<PreviewState>) => {
      state.preview.clientEnv = payload.clientEnv;
      state.preview.deploymentId = payload.deploymentId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const { setProjectId, setActiveLanguageCode, setPreview } =
  jupiterProjectSlice.actions;

export default jupiterProjectSlice.reducer;
