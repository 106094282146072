import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useMatchPath from '@polyai/common/hooks/useMatchPath';
import { Role } from '@polyai/common/types/resources/userManagement';

import api from 'api';
import useSampleProject from 'hooks/useSampleProject';

const ACCOUNT_ROUTE = '/:accountId';
const ACCOUNT_PROJECT_ROUTE = '/:accountId/:projectId';

export const useProjectResources = () => {
  const matchPath = useMatchPath();
  const { inSampleProject } = useSampleProject();

  const { accountId, projectId } = useMemo(() => {
    let match = matchPath(ACCOUNT_PROJECT_ROUTE, false);

    if (!match) {
      match = matchPath(ACCOUNT_ROUTE, false);
    }

    return {
      accountId: match?.params?.accountId,
      projectId: match?.params?.projectId,
    };
  }, [matchPath]);

  const queryKey = [accountId, projectId, 'enabledFeature'];

  const { data, isLoading, isError } = useQuery(
    queryKey,
    () => api.getEnabledFeatures(accountId!, projectId),
    {
      enabled: !!accountId && !inSampleProject,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const isProjectReadOnly = useMemo(
    () => data?.role === Role.VIEWER,
    [data?.role],
  );

  return useMemo(
    () => ({
      hasFetchedProjectFeatures: !isLoading && !!data,
      features: data?.features ?? [],
      restrictedFeatures: data?.restricted_features ?? [],
      isProjectReadOnly,
      isFetchingProjectFeatures: isLoading && !!accountId,
      didProjectFeaturesError: isError,
    }),
    [isLoading, data, isProjectReadOnly, accountId, isError],
  );
};
