import { useMemo, useRef } from 'react';

import {
  matchQuery,
  MutationCache,
  Query,
  QueryClient,
  QueryClientProvider,
  QueryKey,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { ComponentWithChildren } from '@polyai/common/types/helpers';

import { setSavedState } from 'ducks/layout/layoutSlice';
import { useToast } from 'hooks/useToast';
import { HTTPError } from './error';
import { handleGlobalErrors } from './response';

interface PolyQueryMeta {}

interface PolyMutationMeta {
  isDraftChange?: boolean;
  invalidates?: QueryKey[];
}

declare module '@tanstack/react-query' {
  interface QueryMeta extends PolyQueryMeta {}
  interface MutationMeta extends PolyMutationMeta {}
}

const CustomQueryClientProvider: ComponentWithChildren = ({ children }) => {
  const dispatch = useDispatch();

  const numMutations = useRef(0);
  const savedTimeout = useRef<NodeJS.Timeout | undefined>();

  const toast = useToast();

  let queryClient: QueryClient | null = null;
  const invalidateQueries = (invalidates: QueryKey[]) => {
    if (!queryClient) {
      return;
    }

    queryClient
      .invalidateQueries({
        predicate: (query: Query) =>
          // invalidate all matching tags at once
          // or everything if no meta is provided
          invalidates.some((queryKey) => matchQuery({ queryKey }, query)) ??
          true,
      })
      .catch((e: Error) =>
        console.error('Error occurred. Could not invalidate queries', e),
      );
  };

  queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onMutate: (_, mutation) => {
            if (mutation.meta?.isDraftChange) {
              if (!numMutations.current) {
                dispatch(setSavedState('saving'));
              }
              numMutations.current += 1;
            }
          },
          onSuccess: (_, __, ___, mutation) => {
            if (mutation.meta?.isDraftChange) {
              dispatch(setSavedState('saved'));
              clearTimeout(savedTimeout.current);
              savedTimeout.current = setTimeout(() => {
                dispatch(
                  setSavedState(0 < numMutations.current ? 'saving' : 'idle'),
                );
                savedTimeout.current = undefined;
              }, 3000);
            }
            if (mutation.meta?.invalidates !== undefined) {
              invalidateQueries(mutation.meta?.invalidates);
            }
          },
          onError: (error, __, ___, mutation) => {
            handleGlobalErrors(error as HTTPError, mutation, toast);

            if (mutation.meta?.isDraftChange) {
              if (savedTimeout.current) {
                return;
              }
              dispatch(
                setSavedState(1 < numMutations.current ? 'saving' : 'idle'),
              );
            }
          },
          onSettled: (_, __, ___, ____, mutation) => {
            if (mutation.meta?.isDraftChange) {
              numMutations.current = Math.max(0, numMutations.current - 1);
            }
          },
        }),
        defaultOptions: {
          queries: {
            cacheTime: 300000,
            staleTime: 60000,
            retry: false,
          },
        },
      }),
    // adding toast as dep causes querying issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
