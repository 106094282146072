import ENDPOINTS from 'api/endpoints';
import { doGet } from 'api/request';
import {
  EntireSampleProject,
  SampleProjectsResponse,
} from 'api/resources/sampleProjects/types';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';

export const getSampleProjects = () =>
  doGet<SampleProjectsResponse>(
    ENDPOINTS.SAMPLE_PROJECTS,
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );

export const getSampleProject = (projectId: string) => {
  return doGet<EntireSampleProject>(
    generateNextPath(ENDPOINTS.SAMPLE_PROJECT, { projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};
